<template>
  <el-drawer
    :title="drawerTitle"
    custom-class="g-drawer"
    :wrapper-closable="false"
    :visible="drawerVisible"
    size="600px"
    @close="close"
  >
    <div class="g-drawer-c">
      <el-form ref="form" :model="drawerForm" label-width="80px" :rules="rules" label-position="top" :disabled="formDisabled">
        <el-form-item label="关联用户" prop="ext1">
          <div style="line-height: 15px;width: 50%;">
            <UserInfoCard :need-change-user-info="false" :need-match="false" :user-info="companyInfo" :show-bg="true" />
          </div>
        </el-form-item>
        <el-form-item label="产品名称" prop="prodName">
          <el-input v-model="drawerForm.prodName" maxlength="30" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="产品标签" prop="resType">
          <!-- allow-create
            default-first-option filterable -->
          <el-select
            v-model="drawerForm.resType"
            class="g-inp"
            clearable
            multiple
            placeholder="请选择"
            @change="changeLabel"
            @visible-change="handleVisibleChange"
          >
            <template #empty>
              <LabelList ref="labelList" :init-label-data="initLabelData" @selectLabel="selectLabel" />
            </template>
          </el-select>
          <div class="tip">输入后回车提交，产品标签会在发单时随单显示
          </div>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="产品单价" prop="prodPrice">
              <el-input v-model="drawerForm.prodPrice" placeholder="请输入" clearable maxlength="10" @input="validateNumber('prodPrice', 10)">
                <template v-slot:append>
                  <span class="unit">元/吨</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合理损耗" prop="permitLose">
              <div class="permit-type-wrap">
                <el-radio-group v-model="drawerForm.extMap.permitType">
                  <el-radio :label="1">按单计</el-radio>
                  <el-radio :label="2">按车计</el-radio>
                </el-radio-group>
              </div>
              <el-input v-model="drawerForm.extMap.permitLose" placeholder="请输入" clearable maxlength="6" @input="validateNumberMultilevel('extMap','permitLose',6)">
                <template v-slot:append>
                  <span class="unit">‰</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="drawerForm.remark" type="textarea" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </div>
    <div class="g-btn-c">
      <el-button @click="close">取消</el-button>
      <el-button v-if="drawerTypeDesc==='add'||drawerTypeDesc==='edit'" type="primary" @click="submit">提交</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="danger" @click="deleteClick">删除</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="primary" @click="drawerTypeDesc = 'edit'">编辑</el-button>
    </div>
  </el-drawer>
</template>

<script>

import { addProduct, getDetailProduct, editProduct, deleteProduct } from '@/api/product';
// import { getTypeCode } from '@/api/typeCode';
import LabelList from './labelList';
export default {
  components: { LabelList },
  props: {
    // 详情类型 add 新增 edit编辑 detail详情
    drawerType: {
      type: String,
      default() {
        return 'add';
      },
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    drawerId: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    companyInfo: {
      type: Object,
      default() {
        return {
          // companyId: null,
        };
      },
    },
  },
  data() {
    return {
      drawerTypeDesc: '',
      drawerTitleObj: {
        'add': '新增产品',
        'edit': '编辑产品',
        'detail': '查看产品',
      },
      drawerForm: {
        resType: '',
        extMap: {
          permitType: 1,
          permitLose: '',
        },
      },
      rules: {
        prodName: [
          { required: true, message: '请输入产品名称', trigger: 'change' },
        ],
      },
      labelList: [],
      initLabelData: [],
    };
  },

  computed: {
    formDisabled() {
      return this.drawerTypeDesc === 'detail';
    },
    drawerTitle() {
      return this.drawerTitleObj[this.drawerTypeDesc];
    },
  },

  watch: {
    // 监听路由变化，如果发生变化则关闭drawer
    '$route': function(to, from) {
      this.drawerVisible = false;
    },
  },
  methods: {
    open() {
      this.drawerTypeDesc = this.drawerType;
      this.$nextTick(() => {
        this.$refs.labelList.getLabelData();
      });
      if (this.drawerTypeDesc === 'detail' || this.drawerTypeDesc === 'edit') {
        this.getDetail();
      }
    },
    close() {
      this.drawerForm = {
        resType: '',
        extMap: {
          permitLose: '',
        },
      };
      this.initLabelData = [];
      this.labelList = null;
      this.$refs.form.resetFields();
      this.$emit('update:drawerVisible', false);
    },
    // 获取标签数组
    // getLabelData() {
    //   const params = {
    //     resType: 7,
    //     // page: 1,
    //     // size: 999,
    //   };
    //   getTypeCode(params).then(res => {
    //     this.labelList = res || [];
    //   });
    // },
    //
    selectLabel(e) {
      this.emitSelectLabel = e;
      this.drawerForm.resType = e.map((item) => item.resValue);
    },
    handleVisibleChange(visible) {
      if (visible) {
        // 加载下拉表格的数据
        this.$refs.labelList.getLabelData();
      }
    },
    // 选定标签事件c
    changeLabel(val) {
      this.initLabelData = val;
      // this.emitSelectLabel.
    },
    getDetail() {
      getDetailProduct({ id: this.drawerId }).then(res => {
        // 回显标签
        if (res.resType) {
          res.resType = res.resType.split(',');
          this.initLabelData = res.resType;
        }
        this.drawerForm = res || {};
        // this.getLabelData();
      });
    },
    deleteClick() {
      const params = {
        id: this.drawerId,
      };
      deleteProduct(params).then(res => {
        // if (res) {
        this.$baseMessage('删除成功!', 'success');
        this.$emit('drawerSubmit');
        this.close();
        // }
      });
    },
    submit() {
      // type 获取type 分别对应api提交
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          // if (typeof this.drawerForm.resType !== 'number') {
          // // } else {
          //   const needLabelArray = this.labelList.filter(d => d.resCode === this.drawerForm.resType) || [];
          if (this.drawerForm.resType.length) {
            this.drawerForm.resType = this.drawerForm.resType.join();
          } else {
            this.drawerForm.resType = '';
          }
          const drawerForm = JSON.parse(JSON.stringify(this.drawerForm));
          try {
            const apiMap = {
              'add': addProduct,
              'edit': editProduct,
            };
            const res = await apiMap[this.drawerTypeDesc](drawerForm);
            if (res) {
              this.$baseMessage(`${this.drawerTitle}成功!`, 'success');
              this.$emit('drawerSubmit');
              this.close();
            }
          } catch (error) {
            console.log(error);
          }
          // const addParams = {
          //   ...this.companyInfo,
          //   ...drawerForm,
          // };
        }
      });
    },
    validateNumber(val, limitNum, decimals) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.drawerForm[val])) {
        this.drawerForm[val] = '';
      }
      // 保留两位小数
      const price = this.drawerForm[val].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.drawerForm[val] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.drawerForm[val] = pArr[0];
      }
    },
    validateNumberMultilevel(pval, cval, limitNum, decimals) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.drawerForm[pval][cval])) {
        this.drawerForm[pval][cval] = '';
      }
      // 保留两位小数
      const price = this.drawerForm[pval][cval].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.drawerForm[pval][cval] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.drawerForm[pval][cval] = pArr[0];
      }
    },
  },
};

</script>
<style lang='scss' scoped>
.tip{
  font-size: 12px;
  color:#909394;
}
.permit-type-wrap{
  position: absolute;
  margin-top: -46px;
  right: 0;
}
</style>
