<template>
  <el-dialog :title="title" :visible="dialogVisible" width="450px" append-to-body @open="open" @close="close">
    <LabelList ref="labelList" type="edit" class="label-container" @selectLabel="selectLabel" />
    <span slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// addTypeCode,editTypeCode,deleteTypeCode ,batchDeleteTypeCod
import LabelList from './labelList';
export default {
  components: { LabelList },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return '管理产品标签';
      },
    },
  },
  data() {
    return {
    };
  },
  methods: {
    open() {
      this.$nextTick(() => {
        this.$refs.labelList.getLabelData();
      });
    },
    close() {
      this.$emit('update:dialogVisible', false);
    },
    submit() {
      this.close();
    },
    selectLabel(e) {
      console.log(e, 'eee');
    },
  },
};

</script>
<style lang='scss' scoped>
.label-container{
    padding: 0;
}
</style>
