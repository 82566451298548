<template>
  <div class="basic-data-wrap flex">
    <leftPanel ref="leftPanel" :class="['left-wrap',isFold?'left-half':'left-all']" :is-fold.sync="isFold" company-type="all" @changeCompany="changeCompany" @leftCompanyData="leftCompanyData" />
    <div :class="['right-wrap g-search-form-table',isFold?'right-half':'right-all']">
      <search-form id="search-form" :model="form" :count="1" label-width="auto" :collapse="false" @search="searchHandle">
        <search-item label="" prop="prodName">
          <el-input v-model.trim="form.prodName" clearable placeholder="产品名称" />
        </search-item>
      </search-form>
      <Table
        :loading="loading"
        :table-height="tableHeight"
        :show-selection-change="true"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-btns="tableBtns"
        :pagination="pagination"
        :multiple-selection.sync="multipleSelection"
        @selectTime="selectTime"
        @changeSort="changeSort"
        @sizeChange="sizeChange"
        @pageChange="pageChange"
      >
        <template #headOther>
          <div class="label-wrap">
            <div class="right main-c" @click="dialogVisible = true">管理产品标签</div>
          </div>
        </template>
        <template v-slot="{ col, row }">
          <div v-if="col.prop === 'resType'">
            <template v-if="row.resType">
              <span v-for="(item, index) in row.resType" :key="index" class="label-item">
                {{ item }}
              </span>
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>
      </Table>
      <Drawer :drawer-visible.sync="drawerVisible" :drawer-type="drawerType" :drawer-id="id" :company-info="companyInfo" @drawerSubmit="drawerSubmit" />
      <LabelDialog :dialog-visible.sync="dialogVisible" />
    </div>
  </div>
</template>

<script>
import { getProduct, batchDeleteProduct } from '@/api/product';
import { exportByJson, parseTime } from '@/utils';
import leftPanel from '@/views/basicData/components/leftPanel';
import Drawer from './drawer';
import LabelDialog from './labelDialog';
export default {
  name: 'BasicdataProduct',
  components: { leftPanel, Drawer, LabelDialog },
  filters: { parseTime },
  data() {
    return {
      isFold: false,
      tableHeight: 'auto',
      companyInfo: {}, // 当前所选的左侧公司信息 相关接口需要此参数
      loading: false,
      form: {},
      tableBtns: [
        {
          label: '新增产品',
          icon: 'el-icon-circle-plus-outline',
          evt: () => {
            this.openDialog('add');
          },
        },
        {
          label: '删除',
          type: 'danger',
          // plain: true,
          evt: () => {
            this.delete();
          },
        },
        {
          label: '导入',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
        {
          label: '导出',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
      ],
      tableCols: [
        { label: '产品名称', prop: 'prodName', fixed: 'left',
          type: 'jump', width: 90, evt: (data) => { this.openDialog('detail', data); } },
        // { label: '关联调度', prop: 'ext1Name' },
        { label: '关联标签', prop: 'resType', width: 170, type: 'customize' },
        { label: '产品单价(元/吨）', prop: 'prodPrice', width: 130 },
        { label: '合理损耗', type: 'objShow', objShow: { keyPar: 'extMap', keyChild: 'permitLose' }, unit: ' ‰' },
        { label: '创建人', prop: 'createUsername' },
        { label: '创建时间 ', prop: 'createTime', type: 'filterTime', width: '160' },
        { label: '更新人', prop: 'updateUsername' },
        { label: '更新时间', prop: 'updateTime', sortable: 'custom', headTimeSelect: true, width: 170, type: 'filterTime' },
        {
          label: '操作',
          type: 'operation',
          fixed: 'right',
          btns: [
            {
              label: '编辑',
              evt: (data) => {
                this.openDialog('edit', data);
              },
            },
          ],
        },
      ],
      tableData: [],
      multipleSelection: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      id: null,
      drawerType: 'add',
      drawerVisible: false,
      dialogVisible: false,
    };
  },
  created() {
    // this.getList();
  },
  mounted() {
    this.$nextTick(() => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize, true);
  },
  deactivated() {
    window.removeEventListener('resize', this.handleResize, true);
  },
  methods: {
    handleResize() {
      const screenHeight = document.documentElement.clientHeight;
      this.formHeight = document.getElementById('search-form').clientHeight;
      const otherHeight = this.formHeight + 14 + 82 + 20 + 56 + 52 + 20;
      this.tableHeight = screenHeight - otherHeight + 'px';
    },
    // 获取列表数据
    getList() {
      this.loading = true;
      const queryForm = JSON.parse(JSON.stringify(this.form));
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: this.pagination.page,
        size: this.pagination.size,
      };
      getProduct(params)
        .then((res) => {
          let records;
          if (res?.records) {
            records = res.records.map(d => {
              return {
                ...d,
                resType: d.resType ? d.resType.split(',') : '',
              };
            });
          } else {
            records = [];
          }
          this.tableData = records || [];
          this.pagination = {
            page: res?.current || 1,
            size: res?.size || 10,
            total: res?.total || 0,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查询按钮操作
    searchHandle() {
      // 防止点击重置置空以下参数影响筛选
      this.form.companyId = this.companyInfo.companyId; // 企业id
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    // 删除所选
    delete() {
      if (!this.multipleSelection.length) {
        this.$baseMessage('您还未勾选,请先勾选!', 'error');
        return;
      }
      const params = {
        ids: this.multipleSelection.map((d) => d.id) || null,
      };
      batchDeleteProduct(params).then((res) => {
        this.$baseMessage('删除成功!', 'success');
        this.getList();
      });
    },
    // 前端导出excel
    onExport() {
      const queryForm = JSON.parse(JSON.stringify(this.form));
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: 1,
        size: 9999,
      };
      getProduct(params)
        .then((res) => {
          if (!res) {
            this.$baseMessage('暂无数据可导出!', 'error');
            return;
          }
          const header = ['产品名称', '产品标签', '产品单价(元/吨）', '合理损耗', '创建人', '创建时间', '更新人', '更新时间'];
          const exportData = res.records.map((item) => {
            return {
              prodName: item.prodName,
              resType: item.resType,
              prodPrice: item.prodPrice,
              permitLose: item.extMap ? item.extMap.permitLose : '-',
              createUsername: item.createUsername,
              createTime: parseTime(item.updateTime),
              updateUsername: item.updateUsername,
              updateTime: parseTime(item.updateTime),
            };
          });
          exportByJson(exportData, header, '驾驶员列表.xlsx');
        })
        .catch((err) => {
          console.log(err);
          this.$baseMessage('导出失败', 'error');
        });
    },
    changeSort(val) {
      console.log('[ val ] >', val);
    },
    selectTime(time) {
      this.form.updateTimeBegin = time ? time[0] : '';
      this.form.updateTimeEnd = time ? time[1] : '';
      this.getList();
    },
    // 打开侧面抽屉 进行新增/编辑/查看操作
    openDialog(type, data) {
      this.id = data?.id || null;
      this.drawerType = type;
      this.drawerVisible = true;
    },
    // 抽屉提交事件
    drawerSubmit() {
      this.getList();
    },
    // left返回的id
    leftCompanyData(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
      // this.getDispatch();
    },
    // 左侧切换公司 重新发起请求
    changeCompany(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
      // this.form.ext1 = null;
      // this.getDispatch();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/basicData.scss";
.label-wrap{
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right:20px;
  cursor: pointer;
}
.label-item{
  border: 1px solid #94C4FF;
  color: $blue;
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  margin-right: 3px;
  font-size: 12px;
  height: 22px;
  line-height: 20px;
}
</style>
