import request from '@/plugins/axios';
export const getProductOld = (data) => {
  return request.post(`/resource/product/list`, data).then((res) => {
    return res;
  });
};
export const getProduct = (data) => {
  return request.post(`/resource/middle/product/list`, data).then((res) => {
    return res;
  });
};
export const addProduct = (data) => {
  return request.post('/resource/product/add', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const getDetailProduct = (data) => {
  return request.get('/resource/product/detail', data).then((res) => {
    return res;
  });
};
export const editProduct = (data) => {
  return request.post('/resource/product/update', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const deleteProduct = (data) => {
  return request.post('/resource/product/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const batchDeleteProduct = (data) => {
  return request.post('/resource/product/batch/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};

